import React, { useState } from "react";
import LanguageIcon from "../images/languageChange.svg";
import SearchIcon from "../images/search.svg";
import Cloud from "../images/cloud.svg";
import Visiblity from "../images/visiblity.svg";
import Rain from "../images/raincloud.svg";
import LanguageSelection from "./LanguageSelection";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getDayOfWeek,
  formatDateToDayMonth,
  translateHindi,
} from "../Utility/comman";

const CurrentWeather = ({ search, setSearch }) => {
  const { current, location, forecast } = useSelector((state) => state.adddata);
  // console.log(forecast[1]);
  // console.log(current);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Select Language");
  let data = [
    { id: 1, text: t("Visibility"), data: `${current?.vis_km} km` },
    { id: 2, text: t("Humidity"), data: `${current?.humidity} %` },
    { id: 3, text: t("Wind"), data: `${current?.wind_kph} km` },
  ];

  let data2 = [
    {
      id: 1,
      text: [
        { [t("Precipitation")]: `${forecast[1]?.day?.totalprecip_in} in` },
        { [t("Visibility")]: `${forecast[1]?.day?.avgvis_km} km` },
        { [t("Humidity")]: `${forecast[1]?.day?.avghumidity} %` },
        { [t("Wind")]: `${forecast[1]?.day?.maxwind_kph} km` },
      ],
      data: forecast[1],
    },
    {
      id: 2,
      text: [
        { [t("Precipitation")]: `${forecast[2]?.day?.totalprecip_in} in` },
        { [t("Visibility")]: `${forecast[2]?.day?.avgvis_km} km` },
        { [t("Humidity")]: `${forecast[2]?.day?.avghumidity} %` },
        { [t("Wind")]: `${forecast[2]?.day?.maxwind_kph} km` },
      ],
      data: forecast[2],
    },
  ];
  const handleInputChange = (event) => {
    // console.log(event.target.value);
    setSearch(event.target.value);
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // console.log(search)
    let city = search.split(" ").join("-");
    navigate(`/${city}`);
    // handleSearch(search);
  };
  // const showConvertedText = async (text) => {
  //   try {
  //     const translatedText = await translateHindi(text);
  //     return translatedText;
  //   } catch (error) {
  //     console.error("Error in showConvertedText:", error);
  //     return text; // Fallback to the original text in case of an error
  //   }
  // };

  return (
    <div className=" grid xl:grid-cols-3 grid-cols-1 gap-6 py-5">
      {/* left side */}
      <div
        className="md:col-span-2 rounded-xl lg:p-8 p-5 flex flex-col justify-between max-h-[450px] "
        style={{
          backgroundImage: "url('/assets/left.svg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div>
          {/* header */}
         
          <div className="flex flex-col lg:flex-row justify-between">
           
          <div>
          <div className="lg:text-[32px]  text-xl  md:text-2xl font-bold text-white text-center lg:text-start">
              {location?.name} {location.region} {location.country}
            </div></div>
            <div className="flex gap-[10px]  mt-5 lg:mt-0">
              {/* language change */}
              {/* <div className="relative">
      <div
        className="flex justify-center items-center xl:w-[52px] w-10 border rounded-lg p-3 bg-white bg-opacity-10 backdrop-blur cursor-pointer"
        onClick={handleDropdownToggle}
      >
        <img src={LanguageIcon} alt="languagechange" />
       
      </div>

      {isOpen && (
        <div className="absolut text-white top-full left-0 mt-2 w-full border border-gray-300 rounded-lg shadow-lg">
          <div
            className="px-3 py-2 cursor-pointer "
            onClick={() => handleLanguageSelect('hi')}
          >
            Hindi
          </div>
          <div
            className="px-3 py-2 cursor-pointer"
            onClick={() => handleLanguageSelect('en')}
          >
            English
          </div>
        </div>
      )}
             </div> */}
              {/* search box */}
            
            <div className="flex items-center w-full  md:w-1/2 md:mx-auto lg:w-auto lg:mx-auto border lg:p-3 p-1 rounded-lg lg:gap-2 gap-1 inset-0 bg-white bg-opacity-10 backdrop-blur ">
                <div>
                  <img src={SearchIcon} alt="languagechange" />
                </div>
                <form onSubmit={handleFormSubmit}>
                  {" "}
                  <input
                    type="text"
                    value={search}
                    placeholder={t("TypeLocation")}
                    onChange={handleInputChange}
                    className="bg-transparent outline-none placeholder:text-white text-white lg:text-base text-xs font-medium"
                  />
                  <button type="submit"></button>
                </form>
              </div>
            
            </div>
          </div>
          <div className="mt-[18px] ">
            <div className="font-extrabold xl:text-8xl lg:text-5xl text-3xl text-white text-center lg:text-start">
              {current.temp_c} {t("°C")}
            </div>
            <p className="xl:text-base text-xs text-white font-semibold text-center lg:text-start mt-2">
              {current.last_updated}
            </p>
          </div>
        </div>

        <div className=" flex flex-col lg:flex-row justify-between gap-6 mt-5">
          <div className="flex md:justify-center gap-5 justify-between lg:gap-6 ">
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="px-3 inset-0 bg-white bg-opacity-10 backdrop-blur  border rounded-xl flex flex-col justify-center items-center gap-1 xl:px-5 xl:py-2  py-1 "
                >
                  <div className="text-white xl:text-base text-xs xl:font-semibold">
                    {item.text}
                  </div>
                  <div className="flex gap-1 ">
                    <img
                      src={Visiblity}
                      alt="visible"
                      className="w-4 xl:w-full"
                    />
                    <p className=" whitespace-nowrap border-l text-white pl-1 lg:pl-2 xl:text-xl md:text-lg text-sm font-bold">
                      {item.data}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center  gap-2 mt-5">
            <div className="text-white ">
              <p className="lg:text-base  text-base font-medium whitespace-nowrap text-end">
                {t("Weather Forecast")}
              </p>
              <div className="lg:text-[32px]  text-lg font-semibold whitespace-nowrap text-end">
                {current?.condition?.text}
              </div>
            </div>
            <img src={current?.condition?.icon} alt="cloud" />
          </div>
        </div>
      </div>
      {/* right side */}
      <div
        className=" md:col-span-1 rounded-xl  lg:p-8 p-5  flex flex-col  xl:max-h-[450px] gap-4"
        style={{
          backgroundImage: "url('/assets/right.svg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="text-[32px] text-white font-bold">
          {t("Forecast")}
          <span className="ml-2 font-normal text-xs">{t("next 3 days")}</span>
        </div>

        <div className=" flex  md:flex-row flex-col  gap-8 ">
          {/* top */}
          {data2.map((item) => {
            return (
              <div
                key={item}
                className="px-5 flex flex-1 flex-col gap-2 inset-0 bg-white bg-opacity-10 backdrop-blur py-4 rounded-xl "
              >
                <div className="border-b pb-2  mx-auto">
                  {" "}
                  <p className="text-center whitespace-nowrap text-white font-medium text-sm">
                    {getDayOfWeek(item?.data?.date)}
                  </p>
                </div>
                <div className="flex justify-center">
                  <img
                    src={item.data?.day?.condition?.icon}
                    alt="rain"
                    className="w-10"
                  />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="font-semibold text-xs text-center text-white">
                    {item.data?.day?.condition?.text}
                  </div>
                  <div className="text-base font-bold text-white mt-1">
                    {item.data?.day?.avgtemp_c} {t("°C")}
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-5  ">
                  {item.text.map((i, ind) => {
                    return (
                      <div key={ind} className=" flex justify-between">
                        <div className="flex gap-2 ">
                          <img src={Visiblity} className="" />
                          <div className="text-white">{Object.keys(i)}</div>
                        </div>
                        <div className="text-white">{Object.values(i)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CurrentWeather;
