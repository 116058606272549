import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { format } from 'date-fns';
import { addMinutes } from 'date-fns';
const TempChart = () => {
  //const [apidata,apidata]=useState()
  const currentData=useSelector((state)=>state.adddata.forecast[0])
  console.log(currentData)

  let res=currentData?.hour?.map(item => item.time_epoch)
// console.log(res)
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "STOCK ABC",
        data: [], // Initially empty, we'll set this data in useEffect
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        background: "transparent", // Set background to transparent, because we're using a CSS class for the background
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#FFFFFF"], // White color for the graph line
        width: 2,
      },
      title: {
        text: "Temperature",
        align: "left",
        style: {
          color: "#FFFFFF", // White color for title text
        },
      },
      subtitle: {
        text: "",
        align: "left",
        style: {
          color: "#FFFFFF", // White color for subtitle text
        },
      },
      labels: [], // Initially empty, we'll set this data in useEffect
      xaxis: {
        type: "datetime",
        labels: {
          style: {
            colors: "#FFFFFF", // White color for x-axis labels
          },
        },
        axisBorder: {
          show: false,
          color: "#FFFFFF", // White color for x-axis border
        },
        axisTicks: {
          show: false,
          color: "#FFFFFF", // White color for x-axis ticks
        },
        title: {
          text: "Date",
          style: {
            color: "#FFFFFF", // White color for x-axis title
          },
        },
      },
      yaxis: {
        opposite: false,
        labels: {
          style: {
            colors: "#FFFFFF", // White color for y-axis labels
          },
        },
        axisBorder: {
          show: false,
          color: "#FFFFFF", // White color for y-axis border
        },
        axisTicks: {
          show: false,
          color: "#FFFFFF", // White color for y-axis ticks
        },
        title: {
          text: "",
          style: {
            color: "#FFFFFF", // White color for y-axis title
          },
        },
      },
      legend: {
        horizontalAlign: "left",
        labels: {
          colors: "#FFFFFF", // White color for legend labels
        },
      },
    },
  });
  

  useEffect(() => {
    if (currentData?.hour) {
      const seriesData = currentData.hour.map(item => {
        const date = new Date(item.time_epoch * 1000);
        // Convert to IST by adding 330 minutes (5.5 hours)
        const istTime = addMinutes(date, 330);
        return {
          x: istTime.toISOString(), // Convert IST time to ISO string
          y: item.temp_c
        };
      });
  
      setChartData(prevState => ({
        ...prevState,
        series: [{ name: "Temperature", data: seriesData }],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            type: 'datetime', // Ensure xaxis is of type datetime
          },
        },
      }));
    }
  }, [currentData]);
  
    
  

  return (
    <div  className=" mt-5 rounded-xl border-2  p-8 inset-0 bg-white bg-opacity-20 backdrop-blur">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        
        height={350}
      />
    </div>
  );
};

export default TempChart;
