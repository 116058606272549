import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import { useTranslation } from "react-i18next";
import PageNotFound from "./components/PageNotFound";
// import LanguageSelection from ;
export const AppRoutes = () => {
  return (
    <Router>
      
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />

        <Route
          path="/:country/:state/:city"
          element={
            <Layout>
               <Home />
            </Layout>
          }
        />
       
          <Route
          path="/:state/:city"
          element={
            <Layout>
               <Home />
            </Layout>
          }
        />
         
          <Route
          path="/:city"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout>
              <PageNotFound/>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};
