// import bg from "assets/bg.svg"

// import LanguageSelection from "../components/LanguageSelection";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
const Layout = ({ children }) => {
  const location = useLocation();
  const data = useSelector((state) => state.adddata);

  const canonicalUrl = `https://www.maussam.com${location?.pathname}`;
  // const pageTitle = `मौसम आज का और कल का ${
  //   data?.location?.name +
  //   " " +
  //   data?.location?.region +
  //   " " +
  //   data?.location?.country
  // }`;
  // console.log(pageTitle)
  const pageTitle = "मौसम";
  return (
    <div
      className=" flex flex-col min-h-screen"
      style={{
        backgroundImage: "url('/assets/bg.svg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
        <title>{pageTitle || "मौसम आज का और कल का"}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="px-10 pt-10 flex gap-5 lg:gap-0">
        <div className="text-white text-3xl flex  gap-10    ">
          <img src="/assets/Logo-V2.svg" alt="मौसम" className="w-20" />
         
           
            {/* <h2 className="">आज का और कल का आपके शहर और गाँव का!</h2> */}
          
        </div>
        <div className="text-8xl   text-white xl:pl-[450px] md:pl-[150px] lg:pl-[250px]">मौसम</div>
        {/* <LanguageSelection/> */}
      </div>
      <div className="lg:px-10 px-3 py-10 flex-1 ">{children}</div>
    </div>
  );
};

export default Layout;
