import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  location:{},
  current:{},
  forecast:{},
}

export const dataSlice = createSlice({
  name: 'adddata',
  initialState,
  reducers: {
    setDatainRedux: (state, action) => {
      // console.log(action.payload)
        state.location = action.payload.location;
        state.forecast = action.payload.forecast.forecastday;
        state.current=action.payload.current;
      }
    },
    
  
})

export const { setDatainRedux } = dataSlice.actions

export default dataSlice.reducer