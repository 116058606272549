import loader from "./images/WeatherIcons.gif";
import { useGeolocation } from "./CustomHooks/useGeolocation";
import { AppRoutes } from "./AppRoutes";
// import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
function App() {
  const { t } = useTranslation();
 
  // const { coordinates, isLoading, error } = useGeolocation();
  // if (error) {
  //   return alert(error);
  // }
  // if (isLoading) {
  //   return (
  //     <div
  //       className=" flex justify-center min-h-screen items-center text-center "
  //       style={{
  //         backgroundImage: "url('/assets/bg.svg')",
  //         backgroundSize: "cover",
  //         backgroundPosition: "center",
  //       }}
  //     >
  //       <div className="">
  //         <img
  //           src={loader}
  //           style={{ width: "30rem", WebkitUserDrag: "none" }}
  //           alt="Loading"
  //         />
  //         <h3 className="text-white text-3xl font-semibold">
  //           {t("Detectingyourlocation")}
  //         </h3>
  //         <h3 className="text-white text-xl font-normal mt-5">
  //           {t("loadingbottomtext1")}
  //           <br /> {t("loadingbottomtext1")}
  //         </h3>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="App">
      {/* <Helmet>
        <title>मौसम आज का और कल का</title>
        <link rel="canonical" href="https://www.maussam.com/" />
      </Helmet> */}
      <AppRoutes></AppRoutes>
    </div>
  );
}

export default App;
