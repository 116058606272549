import React, { useState, useEffect } from "react";
import CurrentWeather from "../components/CurrentWeather";
import TempChart from "../components/TempChart";
import Precipitation from "../components/Precipitation";
import Content from "../components/Content";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setDatainRedux } from "../Redux/Slices/dataSlice";
const Home = () => {
  const navigate = useNavigate();
  const { city, state, country } = useParams();
  // console.log(state,city)
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const [search, setSearch] = useState();
  // console.log("path name",currentPath)
  // useEffect(() => {
  //   if (!city && !state && !country) {
  //     if (currentPath == "/") {
  //       const getPosition = () => {
  //         return new Promise((resolve, reject) => {
  //           navigator.geolocation.getCurrentPosition(resolve, reject);
  //         });
  //       };

  //       if (navigator.geolocation) {
  //         getPosition()
  //           .then(async (position) => {
  //             // console.log("position.coords",position.coords)
  //             let res = await axios.get(
  //               `https://api.weatherapi.com/v1/forecast.json?key=76ff95db49274b4381d125432241106&q=${position.coords.latitude},${position.coords.longitude}&days=3&aqi=no&alerts=yes`
  //             );
  //             getWeather(res.data);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       } else {
  //         alert("Geolocation not available");
  //       }
  //     }
  //   }
  //   handleSearch(state ? city + " " + state : city);
  // }, [city, currentPath]);
  useEffect(() => {
    const fetchData = async () => {
      if (!city && !state && !country) {
        if (currentPath === "/") {
          try {
            // Fetch location data
            let res = await axios.get("https://ipinfo.io/json");
            const { loc} = res.data;
            // console.log(loc)
            // Fetch weather data
            let weatherRes = await axios.get(
              `https://api.weatherapi.com/v1/forecast.json?key=76ff95db49274b4381d125432241106&q=${loc}&days=3&aqi=no&alerts=yes`
            );
            
            getWeather(weatherRes.data);
          } catch (error) {
            console.error("Error in fetching data", error);
            alert("Failed to fetch weather information.");
          }
        } else {
          alert("Geolocation not available");
        }
      }

      // Call handleSearch with the appropriate parameters
      handleSearch(state ? `${city} ${state}` : city);
    };

    fetchData();
  }, [city, state, country, currentPath]);
  const handleSearch = async (searchCity) => {
    //console.log(searchCity);
    setSearch(searchCity);
    try {
      const response = await axios.get(
        "https://translation.googleapis.com/language/translate/v2",
        {
          params: {
            q: searchCity,
            target: "en",
            key: "AIzaSyDtF8BD4IYJzUKvDfoNLTopvBn10PTcMBo", // Replace with your actual API key
          },
        }
      );
     // console.log("response from api text change", response);
      if (
        response.data &&
        response.data.data &&
        response.data.data.translations
      ) {
        let translatedText = response.data.data.translations
          ? response.data.data.translations[0].translatedText
          : searchCity;
        let res = await axios.get(
          `https://api.weatherapi.com/v1/forecast.json?key=76ff95db49274b4381d125432241106&q=${translatedText}&days=3&aqi=no&alerts=yes`
        );
        getWeather(res.data);

        //  console.log("text",response.data.data.translations[0].translatedText)
        //  setCity(response.data.data.translations?response.data.data.translations[0].translatedText:searchCity);
        //  getWeather(response.data.data.translations[0].translatedText);
      }
    } catch (error) {
      // getWeather(searchCity)
      console.error("Error translating text:", error);
    }
  };

  const getWeather = async (data) => {
    dispatch(setDatainRedux(data));
    // console.log("whether response",data)
  };
  return (
    <div>
      <CurrentWeather
        search={search}
        setSearch={setSearch}
        handleSearch={handleSearch}
      />
      {/* <TempChart/>
     <Precipitation/> */}
      <Content />
    </div>
  );
};

export default Home;
