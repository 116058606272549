import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "hi",
    resources: {
      en: {
        translation: {
            Detectingyourlocation: "Detecting your location",
            loadingbottomtext1:"Your current location will be displayed on the App",
             loadingbottomtext2:"& used for calculating real-time weather.",
             TypeLocation:"Search Location",
             '°C':"° C",
             AM:"AM",
             PM:"PM",
             Visibility:"Visibility",
             Humidity:"Humidity",
             Wind:"Wind",
             m:"m",
             "Weather Forecast":"Weather Forecast",
             "Partly Cloudy":"Partly Cloudy",
             "Forecast":'Forecast',
             "next 3 days":'next 2 days',
             "Precipitation":"Precipitation"

        },
      },
      hi: {
        translation: {
          Detectingyourlocation: "आपके स्थान का पता लगाना",
           loadingbottomtext1:"आपका वर्तमान स्थान ऐप पर प्रदर्शित किया जाएगा",
             loadingbottomtext2:"& वास्तविक समय मौसम की गणना के लिए उपयोग किया जाता है।",
              TypeLocation:"स्थान खोजें",
               '°C':"डिग्री सेल्सियस",
               "AM":"पूर्वाह्न",
               PM:"बजे",
                 Visibility:"दृश्यता",
                 Humidity:"नमी",
                 Wind:"हवा",
                 m:"मी",
                 "Weather Forecast":"मौसम पूर्वानुमान",
                 "Partly Cloudy":"आंशिक रूप से बादल छाएंगे",
                 "Forecast":'पूर्वानुमान',
                 "next 3 days":'अगले 2 दिन',
                 "Precipitation":"वर्षण"


        },
      },
    },
  });
