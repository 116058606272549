import axios from "axios";
export const getDayOfWeek=(dateString)=> {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const date = new Date(dateString);
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
}
export function formatDateToDayMonth(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const month = months[date.getMonth()];
    return `${day} ${month}`;
}

export const translateHindi = async (text) => {
  try {
    const response = await axios.get('https://translation.googleapis.com/language/translate/v2', {
      params: {
        q: text,
        target: 'hi',
        key: 'AIzaSyDtF8BD4IYJzUKvDfoNLTopvBn10PTcMBo',
      },
    });

    // Return the translated text
    return response.data.data.translations[0]?.translatedText || text;
  } catch (error) {
    console.error('Error translating text:', error);
    return text; 
  }
};

