import React from "react";

const Content = () => {
  return (
    <div className="rounded-xl   lg:p-8  p-4 inset-0 bg-white bg-opacity-20 backdrop-blur my-10 flex flex-col gap-5">
      <div className="flex flex-col gap-5">
        <h1 className="text-7xl font-medium text-white text-center">मौसम</h1>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            <span className="font-bold">मौसम</span> के बारें में जानिए के लिए
            हमारी maussam वेबसाइट को कभी भी खोलें. आज का{" "}
            <span className="font-bold">मौसम</span> और कल का{" "}
            <span className="font-bold">मौसम</span> और{" "}
            <span className="font-bold">मौसम</span> आने वाले किसी भी दिन का
            हमारी वेबसाइट पे जान सकते हैं.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-medium text-white">
          मौसम - वेब एप्लीकेशन का प्रयोग का तरीका:
        </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            हमारी मौसम वेब एप्लीकेशन को प्रयोग कीजिये और जानिए मौसम का हाल आपके
            अपने शहर और गांव का. आप चाहें तो सर्च बटन से अपने शहर के अलावा किसी
            और शहर का मौसम भी जान सकते हैं.{" "}
          </p>
          <p>
            जब भी आप हमारी मौसम एप्लीकेशन पे आएंगे, आप जानेंगे न केवल दिन का
            <span className="font-bold"> तापमान</span> बल्कि इस समय की{" "}
            <span className="font-bold">हवा में आद्रता, हवा की गति</span> और भी
            बहुत कुछ. आप चाहें तो आप आज का मौसम के अलावा आने वाले कल का और बीते
            हुए कल का मौसम भी देख सकते हैं देश के किसी भी शहर का आपकी अपनी भाषा
            में.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">
          मौसम का हाल जानने के फायदे:
        </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            वर्तमान मौसम का अनुमान और आने वाले दिनों के मौसम का पूर्वानुमान या
            फिर बीते दिनों के मौसम की जानकारी का लाभ हर किसी को मिलता है.
          </p>
          <p>
            यदि हम अपने देश की बात करें तो कृषि कार्य और किसानो के लिए मौसम का
            हल जानना और पूर्वानुमान जानना बेहद जरूरी है, वहीँ सरकार के विभिन्न
            विभाग, एयरलाइन्स, निर्माण कार्य, समुंद्री ट्रांसपोर्ट, सड़क परिवहन,
            ट्रैवेलिंग और लगभग हर कार्य के लिए मौसम का हाल जानना नित्यंत आवयशक
            है और इसीलिए हम लाएं हैं आपके शहर और गाँव के मौसम का हल आप की भाषा
            में.
          </p>
          <p>
            आइये जानते हैं मौसम का हाल जानना विभिन्न प्रकार के लोगों के लिए कैसे
            लाभदायक है.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">
          {" "}
          1. कृषि कार्य और किसान भाई
        </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            भारत जैसे कृषि प्रधान देश में किसान भाईओं की निर्भरता सटीक मौसम की
            जानकारी पर बहुत ज्यादा है. मौसम के सटीक आकलन और पूर्वानुमान से किसान
            अपनी फसलों और खेती की उत्पादकता बढ़ा सकता है और नुकसान से बचने के लिए
            पहले ही योजना बना सकता है. मौसम के सटीक अनुमान के हिसाब से किसान
            बुवाई, सिंचाई और कटाई को बेह्तरीक तरीके से योजनाबद्ध तरीके से कर
            सकता है.
          </p>
          <p>
            यह जानना के कब वर्षा होने वाली है और कितनी, या कब सूखा आ सकता है या
            कब ओला वृष्टि हो सकती है महत्वपूर्ण है ताकि इसके आधार पे किसान अपनी
            फसलों की रक्षा के प्रबंध कर सके. यह मौसम एप्लीकेशन हमारे सभी किसान
            भाईओं को एकदम सटीक जानकारी उपलब्ध करवाने के लिए प्रयासरत और
            प्रतिबद्ध रहती है.
          </p>{" "}
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">
          {" "}
          2. परिवहन और यात्रा
        </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            पुराने समय से ही जब लोग पैदल भी चलते थे तब भी मौसम की जानकारी का
            अनुमान लगते थे. आज के आधुनिक युग में जब परिवहन के सांसधन जल, आकाश,
            सड़क और अब तो समुन्द्र के अंदर भी से चलते हैं तो मौसम जानना सबसे
            ज्यादा आवश्यक हो गया है.
          </p>
          <p>
            <span className="font-bold">सड़क मार्ग</span> - चाहे सड़क मार्ग से माल
            ढुलाई का कार्य हो या फिर जनसामान्य सवाई का, सभी परिवहन निगम अपने
            मार्ग में आने वाले मौसम की जानकारी पहले से प्राप्त करते हैं. वर्षा
            की संभावना या ओला वृष्टि की, या बर्फ़बारी की या आंधी तूफ़ान का अनुमान
            परिवहन चलने वालो को बेहतर योजना बनाने की जानकारी देता है. खासतौर पे
            पहाड़ी या बर्फीले इलाको पे तो बिना मौसम के पूर्वानुमान के एक छोटी
            यात्रा का आयोजन भी नहीं किया जाता। यह मौसम एप्लीकेशन ऐसे सभी परिवहन
            करने वाले लोगों को सटीक जानकारी उपलब्ध करवाती है.
          </p>{" "}
          <p>
            <span className="font-bold"> हवाई मार्ग - </span>
            हवाई यात्रा के लिए तो मौसम, हवा का दबाव, वर्षा, अंधी तूफ़ान,
            बर्फ़बारी, ऊंचाई का टेम्प्रेचर यह सब जानना अति महत्व्यपूर्ण शर्तें
            हैं. सभी हवाई अड्डों पर एक Automated Weather Observing System (AWOS)
            लगा होता है जिसके द्वारा मौसम के सभी प्रकार के पूर्वानुमान और
            वर्तमान आकलन किये जाते हैं. वह इतना जरूरी है के बिना उसके एक भी
            फ्लाइट उड़ने की कल्पना भी नहीं की जा सकती.
          </p>
          <p>
            <span className="font-bold">
              Wind direction and velocity meter{" "}
            </span>{" "}
            - हवा की दिशा और गति मापने वाला मीटर: यह उपकरण हवा की गति और दिशा को
            दिखाता है।
          </p>
          <p>
            {" "}
            <span className="font-bold">
              Weather Surveillance Radar -{" "}
            </span>{" "}
            मौसम निगरानी रडार: यह रडार वर्षा, ओले या बर्फ जैसी वर्षण को ढूंढने
            और उसकी गंभीरता को दिखाने में मदद करता है।
          </p>
          <p>
            घिरा हुआ मौसम विज्ञान बगीचा: इसमें सभी मौसम-मापने वाले उपकरण रखे
            जाते हैं।
          </p>
          <p>
            <span className="font-bold">
              A close circuit fenced meteorological garden
            </span>{" "}
            - निम्न-स्तरीय वायु परिवर्तन चेतावनी प्रणाली: यह रनवे के पास स्थापित
            की जाती है ताकि वायु परिवर्तन को पहचान सके। यह अत्यंत महत्वपूर्ण है
            क्योंकि अचानक वायु के झोंके विमान के उड़ान मार्ग को प्रभावित कर सकते
            हैं।
          </p>
          <p>
            <span className="font-bold">A wind shear alert system</span> - ऊपरी
            वायु अवलोकन प्रणाली: यह दबाव, तापमान और सापेक्ष आर्द्रता को मापती
            है। इसके नीचे एक हीलियम से भरा गुब्बारा जुड़ा होता है; एक मापने वाला
            उपकरण जिसे <span>"radiosonde"</span> कहा जाता है, हवा में लगभग{" "}
            <span className="font-bold">1,000</span> फीट प्रति मिनट की गति से
            ऊपर उठता है और जमीन पर कंप्यूटरों को डेटा भेजता है। एक सामान्य मौसम
            गुब्बारे की उड़ान दो घंटे से अधिक समय तक चल सकती है और तब समाप्त
            होती है जब वायुदाब गुब्बारे को फोड़ देता है। इसके बाद{" "}
            <span className="font-bold">radiosonde</span> नारंगी रंग के पैराशूट
            के साथ पृथ्वी पर वापस आता है।
          </p>
          <p>
            <span className="font-bold">जलमार्ग जहाजरानी </span> - वायु मार्ग
            एवं सड़क मार्ग की तरह जलमार्ग या Shipping ट्रांसपोर्टेशन भी पूरी तरह
            से मौसम मापने वाले यंत्रों और गणना पर पूर्णतया निर्भर रहता है.
            प्रत्येक शिप पर सभी प्रकार के मॉडर्न एवं काफी सटीक मौसम उपकरण सदैव
            active state में रहते हैं.{" "}
          </p>
          <p>प्रत्येक शिप पर यह मौसम यन्त्र जरूर होते हैं :</p>
          <p>
            <span className="font-bold">Barometer ( बैरोमीटर )</span> : यह
            वायुमंडल के दबाव{" "}
            <span className="font-semibold">(atmospheric pressure )</span> नापने
            के काम आता है.{" "}
          </p>
          <p>
            <span className="font-bold">Thermometer ( थर्मामीटर )</span> : यह
            मौसम का तापमान मापने के काम आता है.
          </p>
          <p>
            <span className="font-bold">Barograph ( बैरोग्राफ )</span> : यह एक
            मौसम मापक यन्त्र है जो बदलते वायुदबाव को एक चार्ट पर लगातार पेन और
            स्याही के निशान के साथ रिकॉर्ड करता है और जिसे शिप का crew लगातार
            monitor करता है.
          </p>
          <p>
            <span className="font-bold">anemometer ( एनीमोमीटर )</span> : यह
            उपकरण समुंद्री यात्रा के दौरान बदलते वायु वेग{" "}
            <span className="font-semibold">(wind speed) </span>और वायु दबाव{" "}
            <span className="semibold">(Wind pressure)</span> को लगातार मापता
            है.{" "}
          </p>
          <p>
            सभी प्रकार के यातायात माध्यम में मौसम मापक यन्त्र यात्रा को
            सुरक्षित, आसान, और प्रभावशाली बनाते हैं और इनसे जुडी व्यापारिक
            गतिविधिओं को फायदेमंद बनाते हैं.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">
          {" "}
          मौसम को मापने का एक संक्षिप्त इतिहास :
        </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            जबसे मानव सभ्यता विकसित हुई है तभी से मनुष्य मौसम के बारे में जानने
            का प्रयास करता रहा है. बहुत पुरानी बात की जाये तो पुराने यूनान और
            भारत में 400--500 इसा पूर्व में वर्षा को मापने और दर्ज करने के लिए
            Rain gauges (वर्षा मापक यन्त्र) का भी जिक्र आता है जो कालांतर में
            व्याकानिको के द्वारा सन्न 1441 में standardized किया गया. इसको
            कोरिया में Cheugugi कहा गया है.
          </p>
          <p>
            यह कुछ ऐसा दीखता था (Source -{" "}
            <a
              className="cursor-pointer"
              href="https://en.wikipedia.org/wiki/Cheugugi"
            >
              https://en.wikipedia.org/wiki/Cheugugi
            </a>
            )
          </p>{" "}
          <p>
            सन् 1593 में महान गैलेलिओ गलीली ने पानी में अलग अलग भार के glass
            bulbs डाल कर पहला थर्मामीटर बनाया, फिर उन्ही के शिष्य Evangelista
            Torricelli ने सन् 1644 में अनुसन्धान करके पहला बैरोमीटर बनाया जिसे
            के एटमोस्फियरिक प्रेशर नापना शुरू हुआ जो की मौसम के वर्तमान और
            पूर्वानुमान लगाने के लिए एक मील का पत्थर साबित हुआ.
          </p>
          <p>
            मॉडर्न ज़माने में फिर अमेरिका के Utah Agricultural College में दुनिया
            का बीसवां मौसम स्टेशन स्थापित किया गया जहाँ अधिकतम और न्यूनतम तापमान
            और मौसम मापने की प्रक्रिया शुरू हुई.
          </p>
          <p>
            और अब इक्कीसवें सदी में यूएसयू में एक सौर ऊर्जा संचालित पर्यावरणीय
            वेधशाला स्थापित की गई और यह वेधशाला सभी मानक मौसम स्थितियों, सौर
            विकिरण के पांच घटकों, हवा में कार्बन डाइऑक्साइड, और भूमिगत सात
            स्थितियों को मापती है और आपको एक रियल टाइम डाटा उपलब्ध करवाती है।
          </p>
          {/* <img src={}/> */}
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white"> मौसम भारत देश का</div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            यूँ तो दुनिया भर के देशो में अमूमन 4 प्रकार का मौसम माना जाता है
            जैसे के वसंत का मौसम (Spring), ग्रीष्म का मौसम (summer), वर्षा ऋतु
            का मौसम (rains) एवं शिशिर ऋतु (winters) पर हमारे देश भारत के इसके
            अलावा 2 और मौसम ऋतुएं होती हैं जो की हैं शरद ऋतु का मौसम (Autumn) और
            हेमंत ऋतू का मौसम (pre winters). इसीलिए भारत का मौसम संसार के सभी
            देशो में सबसे उत्तम माना जाता रहा है और उसका कारन है यहाँ हर मौसम
            अपने चरम पर न जा कर हल्का फुल्का रहता है.
          </p>
          <p>
            भारत के मौसम की प्रकर्ति और जलवायु में उष्णकटिबंधीय और
            उपोष्णकटिबंधीय दोनों ही मौसम की विशेषताएं हैं, क्योंकि कर्क रेखा इस
            देश के मध्य से होकर गुजरती है, पश्चिम में कच्छ का रण और पूर्व में
            मिजोरम है। भारत का दक्षिणी हिस्सा उष्णकटिबंधीय मौसम में आता है
            उत्तरी हिस्सा उपोष्ण कटिबंध अथवा कोष्ण शीतोष्ण कटिबंध मौसम में माना
            है.
          </p>{" "}
          <p>
            भारत के उत्तर में हिमालय के ऊँचे और बर्फ से ढके पहाड़ो का मौसम होता
            है जहाँ पे भारत के उत्तरी राज्य जम्मू और कश्मीर आते हैं, और दक्षिण
            में हिंद महासागर की वजह से तटीय मौसम होता है जो की न तो बहुत गर्म
            मौसम बनता है न ही ज्यादा ठंडा और एक सम मौसम का अनुभव देता है जहाँ पर
            दक्षिण मौसम के राज्य तमिल नाडु, कर्णाटक आते हैं. पशिचम में अरब सागर
            होने का कारन पश्चिमी भारत का मौसम भी तटीय और सम होता है जहाँ पर
            मुख्य राज्य गुजरात और महाराष्ट्र आते हैं. भारत के पूर्वी राज्य जैसे
            के पश्चिम बंगाल, बिहार में मौसम और तापमान अक्सर गर्म और आद्र रहता है
            वहीँ उत्तर पूर्व के राज्यों जैसे के मेघालय, मिजोरम में अक्सर भरी
            वर्षा का मौसम रहता है या फिर आद्रता के साथ गर्मी का मौसम।{" "}
          </p>
          <p>
            भारत के पश्चिमी घाट के इलाके अपने वर्षा के मौसम की वजह से जाने जाते
            हैं, जैसे के केरल, तमिल नाडु, गोवा, महाराष्ट्र और गुजरात के तटीय
            इलाके जहाँ का वर्षा का मौसम भारत की बहुत सारी नदियों और सहायक नदियों
            को जल प्रदान करता है.
          </p>
          <p>
            आइये आप और हम पहले भारत की 6 ऋतुओं के मौसम के बारे में और विस्तार से
            जाने:
          </p>
          {/* <img src={}/> */}
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">
          1. मौसम - वसंत ऋतु का
        </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            भारत के मौसम में वर्ष की शुरुआत मार्च में मानी जाती है और उस समय के
            मौसम को वसंत ऋतु का मौसम मन जाता है. मौसम में वसंत ऋतु को "महंत" भी
            कहाँ गया है यानि के मौसम का राजा। यह ऋतु मार्च से अप्रैल तक मानी
            जाती है, जो की सर्दियों के मौसम की समाप्ति और गर्मी के मौसम के आगमन
            के बीच का समय है. इस मौसम में न तो मौसम ज्यादा ठंडा होता है ना ही
            गरम, इस मौसम को सबसे सुखद अनुभव के लिए जाना जाता है और फसलों की कटाई
            के बाद भारत के प्रत्येक इलाके में अलग अलग प्रकार के त्यौहार मना के
            इस मौसम का स्वागत किया जाता है जैसे की लोहड़ी, होली, पोंगल और सबसे
            ज्यादा मनाया जाने वाला वसंत पंचमी का त्यौहार जिसमें पतंगबाज़ी का भी
            मौसम होता है.
          </p>
          <p>
            वसंत ऋतु के मौसम में पेड़ो पर नए पत्ते आते हैं, फूल खिलने लग जाते
            हैं, पौधे हरे भरे हो जाते हैं और गर्म मौसम के कारन पहाड़ो से बर्फ
            पिघलने लगती है जिसका असर मैदानी इलाको में सुखद मौसम के रूप में होता
            है.
          </p>{" "}
          <p>
            वसंत ऋतु मौसम का सबसे महत्वपूर्ण पहलू बसंत पंचमी का त्योहार है, जो
            ज्ञान, कला, और संगीत की देवी सरस्वती माँ (A hindu deity of
            knowledge) को समर्पित है। इस दिन, लोग ज्यादातर पीले रंग के कपड़े
            पहनते हैं, जो के सरसों के फूलों, फसलों और वसंत के आगमन का प्रतीक है।
            पढ़ने वाले छात्र या कला सीखने और सीखने वाले गुरु भी वसंत के मौसम में
            सरस्वती माँ से आशीर्वाद के लिए प्रार्थना करते हैं और उनकी वेदी पर
            अपने संगीत वाद्ययंत्र, किताबें, और कलम चढ़ाते हैं। इस मौसम में ऐसा
            मन जाता है के वसंत पंचमी के दिन माता सरस्वती का आशीर्वाद लेने से
            ज्ञान और रचनात्मकता में वृद्धि होती है।
          </p>
          <p>
            वसंत ऋतु के मौसम का भारत के कृषि क्षेत्र में भी बहुत महत्व है। किसान
            इस समय अपनी फसलें काटने के बाद नयी फसलें बोना शुरू कर देते हैं.
            क्योंकि वसंत का मौसम फसलों और कृषि के विकास के लिए अच्छा होता है।
            खेत हरी-भरी हरियाली से भर जाते हैं।
          </p>
          <p>
            इसके अलावा, वसंत ऋतु लोगों के जीवन में ताजगी और पुनर्जीवन की भावना
            लाती है। ठंडी और उदास सर्दियों के बाद, वसंत की गर्मी और सुंदरता मन
            को प्रफुल्लित करती है और आशावाद का संचार करती है। लोग इस सुहावने
            मौसम का आनंद लेने के लिए पिकनिक, क्रिकेट मैच, और प्रकृति की सैर जैसी
            बाहरी गतिविधियों में भाग लेते हैं।
          </p>
          <p>
            मानसिक स्वास्थ के लिए भी वसंत ऋतु का मौसम उत्तम माना गया है और उत्तर
            भारत के मैदानी इलाको में तो इसी मौसम में सबसे ज्यादा विवाह प्रयोजन
            भी किये जाते हैं. शारीरिक स्वास्थ के लिए भी वसंत ऋतु का मौसम काफी
            अनुकूल मौसम होता है हालाँकि इसी मौसम में हल्का फुल्का कफ और जुखाम की
            संभावना रहती है. सम्पूर्ण तरीके से देखें तो यह मौसम इसलिए भी उत्तम
            है के इस मौसम म डेंगू, मलेरिआ जैसी कोई बीमारियां भी नहीं फैलती।
          </p>
          {/* <img src={}/> */}
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">
          2. मौसम - ग्रीष्म ऋतु का
        </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            मौसम ग्रीष्म ऋतु का मौसम वसंत ऋतु के मौसम के बाद आता है जिसके महीने
            हैं अप्रैल मई और जून.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">3. मौसम बारिश का </div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            भारत में अगर सबसे ज्यादा किसी मौसम का इंतजार किया जाता है तो वह है
            बारिश का मौसम। भारत चुकी कृषि प्रधान देश है और उसकी अर्थव्यवस्था
            कृषि पर अधिकतर निर्भर है , तो बारिश की महत्व बढ़ना स्वाभाविक है। तभी
            कहा जाता है कि ‘ का वर्षा जब फसल सुखानी ‘ अर्थात उसे बारिश का क्या
            मतलब जब फसल सूख जाए।
          </p>
          <p>
            भारत में बारिश का मौसम जून के मध्य से शुरू होकर सितंबर तक जाता है।
            भारत में बारिश का मौसम मुख्यता मानसून की वजह से होता है। अरब सागर से
            उठने वाला मानसून सबसे पहले केरल को छूता है और फिर धीरे-धीरे पूरे
            भारत को अपने बादलों की चादर में समेट लेता है।
          </p>
          <p>
            भारत के किसान सबसे ज्यादा बारिश के मौसम का इंतजार करते हैं, क्यूकी
            भारत में सबसे ज्यादा खाई जाने वाली फसल धान/ चावल को बारिश की सबसे
            ज्यादा जरूरत होती है। इसके साथ ही साथ किसान बारिश के मौसम में ज्वार
            बाजरा गन्ना मक्का सोयाबीन कपास जैसी महत्वपूर्ण फैसले उगाते हैं।
            चिलचिलाती गर्मी के मौसम के बाद बारिश का मौसम शरीर और धरती दोनों को
            सुकून पहुंचाने वाला होता है। बारिश के मौसम में जहां तापमान 50 डिग्री
            से घटकर 30 डिग्री तक आ जाता , वहीं दूसरी तरफ सूखी पड़ी धरती बारिश के
            मौसम में हरी भरी हो जाती है । नदी नाले तालाब सब पानी से लबालब भर
            जाते हैं। चारों तरफ हरियाली ही हरियाली हो जाती है इसीलिए कहा जाता है
            कि ‘ सावन के अँधे को सब हरा ही दिखाई पड़ता है' । धरती का जलस्तर
            बारिश के मौसम में बढ़ जाता है जिससे पेयजल की समस्या काफी हद तक दूर
            हो जाती है।
          </p>
          <p>
            साहित्य में अगर किसी मौसम की सबसे ज्यादा चर्चा हुई है तो वह बारिश का
            ही मौसम है क्या राग, क्या पध् और क्या गद्य कोई भी विद्या इस खूबसूरत
            बारिश के मौसम से बची नहीं है। भारतीय फिल्मों में बारिश के मौसम का
            भरपूर इस्तेमाल किया गया है। भारतीय शास्त्रीय संगीत का एक महत्वपूर्ण
            राग् बारिश पर ही आधारित है जिसे हम राग मेघ मल्हार के नाम से जानते
            हैं, राग् मेघ् मल्हार् दो रागो मेघ और मल्हार को मिलाकर रचा गया है।
            रिमझिम गिरे सावन से लेकर टिप टिप बरसा पानी तक पूरा भारतीय फिल्मी
            संगीत बारिश के मौसम में ही सराबोर रहता है ।
          </p>
          <p>
            भारत में सर्वाधिक पूजे जाने वाले महादेव का प्रिय मौसम भी बारिश का
            मौसम ही है। बारिश के मौसम में पढ़ने वाला सावन का महीना भगवान भोलेनाथ
            को ही समर्पित है। भाई बहन का पवित्र त्यौहार रक्षाबंधन भी सावन अर्थात
            बारिश के मौसम में ही पड़ता है।
          </p>
          <p>
            जहां एक तरफ बारिश का मौसम चारों तरफ से सबके लिए हरियाली और खुशियां
            लेकर आता है वहीं दूसरी तरफ बारिश के मौसम में थोड़ी बहुत दुश्वारियां
            और परेशानी भी खड़ी हो जाती है। अति वृष्टि से नदियां उफान पर बहने
            लगती है जिसकी वजह से कुछ क्षेत्रों में बाढ़ के हालात उत्पन्न हो जाते
            हैं। पहाड़ों पर भूस्खलन की घटनाएं बढ़ जाती है जिससे मार्ग अवरुद्ध हो
            जाते हैं और सामान्य जनमानस को कठिनाई का सामना करना पड़ता है।
          </p>
          <p>
            पर कुल मिलाकर देखा जाए तो बारिश का मौसम मनुष्य के जीवन में किसी
            वरदान से काम नहीं है । बारिश का मौसम एक ऊर्जा उमंग का परिचायक है।
            धरती की शीतलता के लिए बारिश का मौसम बहुत ही जरूरी है । बारिश का मौसम
            जीवन जीने का आधार है जिसके बिना मनुष्य के जीवन की कल्पना नहीं की जा
            सकती है। कृषि पेयजल सिंचाई भूजल आदि सबके लिए बारिश का मौसम अति
            महत्वपूर्ण है। किसी भी देश की अर्थव्यवस्था के लिए बारिश का मौसम एक
            प्रमुख घटक है, अगर बारिश के मौसम को मनुष्य के जीवन के लिए संजीवनी
            कहा जाए तो अतिशयोक्ति न होगी।
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-bold text-white">4. मौसम जाड़े का</div>
        <div className="flex flex-col gap-2 text-lg text-white text-normal">
          <p>
            भारत में आप् बारोह महीने मौसम की विविधताओँ का आनंद ले सकते हैं।
            इन्हीं विविधता का एक प्रमुख अंग है जाड़े का मौसम। जाड़ा अपने आप में एक
            जादुई शब्द है। ये एक ऐसा अहसास है जो कि अक्टूबर से शुरू होकर फरवरी
            मार्च तक रहता है। भारत में जाड़े के मौसम की शुरुआत दीपावली के
            त्यौहार से मानी जाती है और जब भारत में, खास तौर पर उत्तर भारत में
            अलाव जलाकर इस जाड़े के मौसम का स्वागत किया जाता है, और फिर मार्च में
            होलिका दहन के साथ इस खूबसूरत और रुमानी मौसम को विदाई दी जाती है।
          </p>
          <p>
            जाड़े का मौसम एक ऐसा समय है जब प्रकृति के अलग अलग रंगों का एहसास हर
            तरफ होने लगता है। क्या बच्चे, क्या बूढ़े और क्या जवान? सब इस जाड़े
            के मौसम का अहसास और आनंद अपने अपने तरीके से लेने लगते हैं। जाड़े के
            मौसम में दिन छोटे और राते लम्बी होती जाती है, दिन की शुरुआत आप कोहरे
            की चादर और ओस की बूँदों से करते हैं और रात हाड़ कपड़ा देने वाली ठंड
            के आगोश में होती है।
          </p>
          <p>
            {" "}
            भारत में जाड़े का मौसम त्योहारों का मौसम माना जाता है।जिसकी शुरूआत
            शरदीय नवरात्रों से शुरू होकर दशहरा , करवा चौथ, जोकि भारतीय हिंदू
            स्त्रियों के लिए एक अहम त्योहार है, जिसमें वह अपने पति की लंबी उम्र
            की कामना का व्रत रखती हैं। दीपावली, जो कि भगवान श्रीराम के वनवास से
            वापस अयोध्या लौटने की खुशी में मनाया जाता है। भाई दूज जिसमें बहनें
            अपने भाइयों की सुख समृद्धि की कामना की पूजा करती हैं।जाड़े के मौसम
            में पूरा भारत त्योहारों के रंग बिरंगे उत्सव में डूबा रहता है।
          </p>
          <p>
            भारत का किसान जाड़े के मौसम का स्वागत गेहूं की फसल बोकर करता है, जो
            कि एक प्रमुख फसल है। इसके साथ ही साथ वह जो, चना, मटर, मसूर, हराचारा,
            आलू, सरसों, सूरजमुखी आदि प्रमुख फसलों की भी बुवाई करता है। जाड़े का
            मौसम परिवार दोस्तों की एकजुटता का मौसम है। जब सब एक साथ इकट्ठा होकर
            अलाव जलाकर मूँगफली खाते हुए इस मौसम का सामूहिक आनंद लेते हैं। जाड़े
            का मौसम भारत में शादियों का भी मौसम कहलाता है।भारत में अधिकतर विवाह
            जाड़े के मौसम में ही होते हैं। जाड़े के मौसम में पाचन क्षमता अच्छी
            होने की वजह से इसे खाने का भी मौसम कहा जाता है। तमाम प्रकार के
            गरिष्ठ पकवान जाड़ों में बड़े शौक से खाये जाते हैं।
          </p>
          <p>
            जाड़े के मौसम का जिक्र हो और साहित्य की दो प्रमुख रचनाओं की बात न
            हो, ऐसा संभव हो ही नहीं सकता है।मुंशी प्रेमचंद की कालजयी रचना ‘पूस
            की रात’ जाड़े के मौसम की मार झेलते हुए एक ऐसे किसान की कहानी है, जो
            विडंबना और बेबसी के बीच पिस जाता है। दूसरी रचना रामधारी सिंह दिनकर
            की ‘ठेले पर हिमालय ‘ , जोकि हर एक की आँखों में एक उमंग और जाड़े के
            प्रति रोमांच से भर देती है। स्वास्थ्य की दृष्टि से भी जाड़े का मौसम
            एक बेहतरीन मौसम माना जाता है, क्योंकि इस मौसम में आम बीमारियों के
            होने का खतरा कम रहता है। शल्य चिकित्सा के लिए भी एक आदर्श मौसम माना
            गया है क्योंकि शरीर के घाव भरने की क्षमता जाड़े के मौसम में अच्छी
            मानी जाती है। यद्यपि बुजुर्ग और बच्चों के लिए इस मौसम में थोड़ी
            सावधानी रखनी चाहिए, खास तौर पर दिल के मरीजों के लिए।
          </p>
          <p>
            कुल मिलाके जाड़े का मौसम एक उमंग, उत्साह, मौज, मस्ती, खानपान, उत्सव
            और त्योहारों का मौसम है। जहाँ एक तरफ मैदानी क्षेत्र कोहरे की चादर
            में लिपटा रहता है, वहीं दूसरी तरफ पर्वतीय क्षेत्रों में झूमकर
            बर्फबारी होती है। जिसकी वजह से हिमालय बर्फ़ की चादर से लिपटा हुआ एक
            हसीन सा ख्वाब दिखता है। पर्यटक इसी ख्वाब को जीने के लिए हिमालय
            क्षेत्रों का रुख करते हैं। जाड़े का मौसम शुरू भी त्योहार से होता है
            और खत्म भी त्यौहार पर। तो आइये गर्म चाय की चुस्कियों के साथ मुँह से
            भाप निकालते हुये इस जाड़े के मौसम का आनंद उठाते है।
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;
